import { AppBarChart } from "../../components/reports/Bar";
import { AppVerticalBarChart } from "../../components/reports/VerticalBar";
import { Col, Row } from "reactstrap";
import { SAMPLE_CHART_DATA } from "./samples/data";

type DT = {
  name: string;
  uv?: number;
  pv?: number;
  amt?: number;
  time?: number;
};
export function Dashboard() {
  const data: DT[] = SAMPLE_CHART_DATA;
  return (
    <Row className="my-1 g-2">
      <Col>
        <AppBarChart<DT> data={data} xKey="name" yKey="time" />
      </Col>
      <Col>
        <AppVerticalBarChart<DT> data={data} xKey="name" yKey="amt" />
      </Col>
    </Row>
  );
}
