import { Button, Spinner } from "reactstrap";

interface Props {
  isLoading: boolean;
}

export function SubmitButton({ isLoading }: Props) {
  return isLoading ? (
    <Button color="primary" disabled>
      <Spinner size={"sm"} /> Loading ...
    </Button>
  ) : (
    <Button color="primary">Save</Button>
  );
}
