import {
  ProjectStatus,
  Company,
  ProjectStage,
  PROJECT_STAGES,
} from "../constants";

type ACTIONTYPE =
  | {
      type: "updateStageStatus";
      payload: { name: string; status: ProjectStatus };
    }
  | {
      type: "addCompany";
      payload: { name: string; company: Company };
    }
  | {
      type: "deleteCompany";
      payload: { name: string; id: string };
    }
  | {
      type: "reset";
      payload: ProjectStage[];
    };

export const initializeProjectStages = (): ProjectStage[] => {
  return PROJECT_STAGES.map((stage) => {
    return {
      companies: [],
      name: stage,
      status: ProjectStatus.NO_INFO,
    };
  });
};

const initProjectStages = (projectStages: ProjectStage[]) => projectStages;

export function ProjectStagesReducer(
  stages: ProjectStage[],
  action: ACTIONTYPE
) {
  switch (action.type) {
    case "updateStageStatus": {
      return stages.map((stage) => {
        if (stage.name === action.payload.name) {
          return Object.assign({}, stage, {
            status: action.payload.status,
          });
        } else {
          return stage;
        }
      });
    }
    case "addCompany": {
      return stages.map((stage) => {
        if (stage.name === action.payload.name) {
          return Object.assign({}, stage, {
            // Check if company exists before adding it to avoid duplicates.
            companies: stage.companies.find(
              (x) => x.id === action.payload.company.id
            )
              ? [...stage.companies]
              : [...stage.companies, action.payload.company],
          });
        } else {
          return stage;
        }
      });
    }
    case "deleteCompany": {
      return stages.map((stage) => {
        if (stage.name === action.payload.name) {
          return Object.assign({}, stage, {
            companies: stage.companies.filter(
              (company) => company.id !== action.payload.id
            ),
          });
        } else {
          return stage;
        }
      });
    }
    case "reset": {
      if (action.payload.length < 1) {
        return initializeProjectStages();
      }
      return initProjectStages(action.payload);
    }
    default: {
      return stages;
    }
  }
}
