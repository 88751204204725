import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

interface SearchInputProps {
  onTextChange: (x: string) => void;
  placeHolderText?: string;
}
export function SearchInput({
  placeHolderText,
  onTextChange,
}: SearchInputProps) {
  const handleTextChange = _.debounce(onTextChange, 500);
  return (
    <div className="input-group my-3">
      <input
        type="text"
        className="form-control"
        aria-label="Search"
        placeholder={placeHolderText || "Search"}
        onChange={({ target }) => {
          handleTextChange(target.value);
        }}
      />
      <span className="input-group-text">
        <FontAwesomeIcon icon={faSearch} />
      </span>
    </div>
  );
}
