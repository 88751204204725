import { useRouteError, isRouteErrorResponse } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex flex-column align-items-center justify-content-center"
    >
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occured.</p>
      {isRouteErrorResponse(error) ? (
        <i>{error?.data || error.statusText}</i>
      ) : (
        <i>{"Unknown error"}</i>
      )}
      <p></p>
    </div>
  );
}
