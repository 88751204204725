import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { Input, FormGroup, Label, FormFeedback } from "reactstrap";

type OptionSelect = "radio" | "checkbox";
class CheckboxParams {
  label?: string;
  type: OptionSelect = "checkbox";
  currentValue?: string;
  options: string[] = [];
}
export function FormCheckbox<T extends FieldValues>(
  props: UseControllerProps<T> & CheckboxParams
) {
  const {
    field,
    fieldState: { error },
  } = useController(props);
  return (
    <div>
      {props.label ? (
        <Label style={{ fontWeight: "500" }}>
          {props.label}{" "}
          <FormFeedback className="d-inline">{error?.message}</FormFeedback>
        </Label>
      ) : null}
      {props?.options.map((option, index) => (
        <FormGroup check key={index}>
          <Input
            {...field}
            id={props.name + index}
            type={props.type}
            value={option}
            onChange={field.onChange}
            invalid={!!error}
            checked={option === field.value}
          />
          <Label for={props.name + index}>{option}</Label>
        </FormGroup>
      ))}
    </div>
  );
}
