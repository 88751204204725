import "./skid.css";
import bosch_img from "../../assets/img/bosch.svg";
import skid_img from "../../assets/img/skid.svg";
import { useRef } from "react";

export function SKIDOverlay() {
  const overlayRef = useRef<HTMLInputElement>(null);

  const handleOverlayClose = () => {
    if (overlayRef.current) {
      overlayRef.current.style.display = "none";
    }
  };
  return (
    <div
      className="skid-overlay"
      ref={overlayRef}
      role="dialog"
      aria-labelledby="skid-overlay-title"
      aria-describedby="skid-overlay-desc"
    >
      <div className="skid-overlay__container">
        <div className="skid-overlay__client">
          <div className="my-custom-client-style">
            <h2 id="skid-overlay-title">
              Site Management is now using SingleKey ID.
            </h2>
            <img src={bosch_img} alt="Bosch Brand Logo" />
            <p id="skid-overlay-desc">
              We are pleased to announce that Site Management is now using
              SingleKey ID.
            </p>
            <p>
              You can create a new SingleKey ID or log in with your existing
              SingleKey ID or Bosch ID credentials.
            </p>
            {/* <a href="#">Continue as guest</a> */}
          </div>
        </div>
        <div className="skid-overlay__cta">
          <img
            className="skid-overlay__logo"
            src={skid_img}
            alt="SingleKey ID"
          />
          <span className="skid-overlay__subtitle">
            One login for many brands
          </span>
          <button
            className="btn btn-outline-light"
            onClick={(e) => {
              e.preventDefault();
              handleOverlayClose();
            }}
          >
            Continue
          </button>
        </div>
        <button
          className="skid-overlay__close"
          onClick={(e) => {
            e.preventDefault();
            handleOverlayClose();
          }}
        >
          <span className="skid-overlay--screen-reader-only">Close</span>
        </button>
      </div>
    </div>
  );
}
