import { Alert } from "reactstrap";
import { ErrorMessage } from "../../services/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

type Props = {
  error?: ErrorMessage;
  errorMessage?: string;
};

export const ErrorHandler = ({ error, errorMessage }: Props) => {
  if (errorMessage) {
    return (
      <div className="row p-2">
        <Alert color={"danger"} className="m-0">
          <p className="my-0">
            <span className="fw-bold">
              <FontAwesomeIcon icon={faWarning} /> ERROR:{" "}
            </span>
            <span>{error?.message || errorMessage} </span>
          </p>
        </Alert>
      </div>
    );
  } else {
    if (!error) return null;
    return (
      <div className="row p-2">
        <Alert color={"danger"} className="m-0">
          <p className="my-0">
            <span className="fw-bold">Error Type: </span>
            <span>{error.name}</span>
          </p>
          <p className="my-0">
            <span className="fw-bold">
              <FontAwesomeIcon icon={faWarning} /> ERROR:{" "}
            </span>
            <span>{error?.message || errorMessage} </span>
          </p>
          <p className="my-0">
            <span className="fw-bold">Status Code: </span>
            <span>{error?.code}</span>
          </p>
        </Alert>
      </div>
    );
  }
};
