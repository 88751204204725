import React, { useEffect, useRef } from "react";
import { GetProjectsDto } from "../../services/dtos/projects.dto";
import CustomIcon from "../../assets/img/pin.svg";

export default function MapContainer({
  center,
  zoom,
  mapListItems,
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
  mapListItems: GetProjectsDto[];
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  let map!: google.maps.Map;

  useEffect(() => {
    if (window.google && window.google.maps && ref.current) {
      map = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        streetViewControl: false,
        mapTypeControl: false,
        keyboardShortcuts: false,
        styles: [
          {
            featureType: "administrative.country",
            elementType: "geometry.stroke",
            stylers: [{ visibility: "off" }],
          },
        ],
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
      });

      mapListItems.forEach((markerData) => {
        const markerPosition = {
          lat: markerData.latitude,
          lng: markerData.longitude,
        };

        const markerOptions: google.maps.MarkerOptions = {
          position: markerPosition,
          map: map,
          title: markerData.name,
        };

        if (
          markerPosition.lat === center.lat &&
          markerPosition.lng === center.lng
        ) {
          markerOptions.icon = {
            url: CustomIcon,
            scaledSize: new google.maps.Size(48, 48),
          };
          markerOptions.animation = google.maps.Animation.BOUNCE;
          markerOptions.zIndex = 100;
        }

        const marker = new window.google.maps.Marker(markerOptions);
      });
    }
  }, [center, mapListItems]);

  return <div ref={ref} style={{ height: "40Vh" }} />;
}
