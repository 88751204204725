import { useMutation, useQuery } from "@tanstack/react-query";
import {
  CompanyDataForm,
  CreateCompanyFormData,
  CreatePartialCompanyDto,
} from "../routes/company/create-company.schema";
import { BASE_API_URL } from "../constants";
import { GetCompaniesDto } from "./dtos/companies.dto";
import { useAxiosInstance } from "../hooks/useAxiosInstance";

export function useCompanyAPI() {
  const axiosInstance = useAxiosInstance();

  const useFetchCompanies = () => {
    return useQuery({
      queryKey: ["companies"],
      queryFn: async (): Promise<Array<GetCompaniesDto>> => {
        const { data } = await axiosInstance.get(
          BASE_API_URL.concat("companies")
        );
        return data;
      },
    });
  };

  const getCompanyByID = async (companyID?: string) => {
    try {
      const { data } = await axiosInstance.get(
        BASE_API_URL.concat(`companies/${companyID}`)
      );
      const result = new CompanyDataForm(data);
      console.log(JSON.stringify(result, null, 2));
      return result;
    } catch (error) {
      console.error(error);
    }
  };

  const searchCompanies = async (search?: string) => {
    try {
      const { data } = await axiosInstance.get<Array<GetCompaniesDto>>(
        BASE_API_URL.concat(`companies?search=${search}`)
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const useCreateCompanyByName = () => {
    return useMutation({
      mutationFn: (partialCompanyDto: CreatePartialCompanyDto) => {
        return axiosInstance.post(
          BASE_API_URL.concat("companies"),
          partialCompanyDto
        );
      },
    });
  };

  const useRegisterCompany = () => {
    return useMutation({
      mutationFn: (companyDto: CreateCompanyFormData) => {
        return axiosInstance.post(BASE_API_URL.concat("companies"), companyDto);
      },
    });
  };

  const useUpdateCompanyDetails = () => {
    return useMutation({
      mutationFn: ({
        companyDto,
        companyID,
      }: {
        companyDto: CreateCompanyFormData;
        companyID: string;
      }) => {
        return axiosInstance.patch(
          BASE_API_URL.concat(`companies/${companyID}`),
          companyDto
        );
      },
    });
  };

  return {
    useFetchCompanies,
    getCompanyByID,
    searchCompanies,
    useCreateCompanyByName,
    useRegisterCompany,
    useUpdateCompanyDetails,
  };
}
