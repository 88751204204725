import { useState, useEffect } from "react";
import { getFormNamesFromErrors } from "../constants/utils";
import { FieldValues, FormState } from "react-hook-form";

export function useScrollToError<T extends FieldValues>(
  formState: FormState<T>
) {
  const onError = () => {
    setCanFocus(true);
  };

  // using a state here to make the "scroll & focus" happen once per submission
  const [canFocus, setCanFocus] = useState(true);

  useEffect(() => {
    if (formState.errors && canFocus) {
      // Sort inputs based on their position on the page. (the order will be based on validaton order otherwise)
      const elements = getFormNamesFromErrors(formState.errors)
        .map((name) => {
          // Special edge case for react-select
          const el = document.getElementById(name);
          if (el) return el;
          else return document.getElementsByName(name)[0];
        })
        .filter((el) => !!el);
      elements.sort(
        (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top
      );

      if (elements.length > 0) {
        let errorElement = elements[0];
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" }); // scrollIntoView options are not supported in Safari
        errorElement.focus({ preventScroll: true });
        setCanFocus(false); // so the form doesn't suddenly jump to the next input that has error.
      }
    }
  }, [formState, canFocus]);

  return { onError };
}
