import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import Select from "react-select/creatable";
import { SingleValue } from "react-select/dist/declarations/src";
import { useCompanyAPI } from "../../services/companies.service";

export interface Option {
  readonly label: string;
  readonly value: string;
}

type Props = {
  handleChange: (x: SingleValue<Option>) => void;
  handleCreation: (x: string) => Promise<AxiosResponse<any, any>>;
  placeholder: string;
  isDisabled: boolean;
  currentValue?: { id: string; name: string };
  ref?: any;
};

export function CreateSelect({
  handleChange,
  placeholder,
  isDisabled = false,
  handleCreation,
  currentValue,
  ref,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [value, setValue] = useState<Option | null>();
  const { useFetchCompanies } = useCompanyAPI();

  const companyQuery = useFetchCompanies();

  useEffect(() => {
    // console.log("useEffect, data changed....");
    if (companyQuery.status === "success") {
      if (companyQuery.data) {
        const options = companyQuery.data.map((company) => ({
          label: company.name,
          value: company.id,
        }));
        setOptions(options);
      }
    }
  }, [companyQuery.data, companyQuery.status]);

  useEffect(() => {
    if (currentValue) {
      setValue({
        label: currentValue.name,
        value: currentValue.id,
      });
    }
  }, [currentValue]);

  if (companyQuery.status === "loading") return <p>Loading...</p>;

  if (companyQuery.status === "error") return <p>Error loading companies</p>;

  const handleCreate = async (inputValue: string) => {
    setIsLoading(true);
    try {
      const { data } = await handleCreation(inputValue);
      const createdOption = {
        label: data.name,
        value: data.id,
      };
      setIsLoading(false);
      setOptions((prev) => [...prev, createdOption]);
      // Update the data
      companyQuery.refetch();
      setValue(createdOption);
      handleChange(createdOption);
    } catch (error) {
      // TODO: Proper logger library to be implemented.
      alert("Company could not be created. Try again.");
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Select
      isClearable
      options={options}
      isDisabled={isDisabled || isLoading}
      isLoading={isLoading}
      value={value}
      onChange={handleChange}
      onCreateOption={handleCreate}
      placeholder={placeholder}
      ref={ref}
    />
  );
}
