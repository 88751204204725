import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import Select, { MultiValue } from "react-select";
import { FormGroup, Label } from "reactstrap";
import { HelperText } from "./HelperText";

interface Option {
  readonly label: string;
  readonly value: string;
}

interface Props {
  options: Array<string>;
  label: string;
}

export function ReactFormMultiSelect<T extends FieldValues>(
  props: UseControllerProps<T> & Props
) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState }) => {
        return (
          <FormGroup>
            <Label style={{ fontWeight: "500" }}>
              {props.label}
              <br />
              <HelperText>* multiple selection possible</HelperText>
            </Label>
            <Select
              {...field}
              inputId={props.name}
              isMulti
              options={props.options.map((option) => ({
                label: option,
                value: option,
              }))}
              value={
                Array.isArray(field.value)
                  ? field.value.map((value: unknown) => {
                      if (typeof value === "string") {
                        return {
                          label: value,
                          value: value,
                        };
                      } else return value;
                    })
                  : [{ label: field.value, value: field.value }]
              }
              onChange={(selectedValue: MultiValue<Option>) => {
                // Changes form values from Option[] to string[]
                field.onChange(selectedValue.map((value) => value.value));
              }}
              styles={{
                control(base) {
                  return {
                    ...base,
                    borderColor: fieldState.error ? "#ed0007" : "lightgrey",
                  };
                },
              }}
            />
            <small style={{ color: "#ed0007" }}>
              {fieldState.error?.message}
            </small>
          </FormGroup>
        );
      }}
    />
  );
}
