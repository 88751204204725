import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { Input, FormGroup, Label, FormFeedback } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";
import { HelperText } from "./HelperText";

class InputParams {
  label?: string;
  type?: InputType;
  disableFloating?: boolean;
  disabled?: boolean;
  inputGroupText?: any;
  children?: any;
  placeholderText?: string;
  helperText?: string;
  customClass?: string;
}
export function FormInput<T extends FieldValues>(
  props: UseControllerProps<T> & InputParams
) {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return (
    <FormGroup>
      {props.label ? (
        <Label for={props.name} style={{ fontWeight: "500" }}>
          {props.label}
          <br />
          {props.helperText ? (
            <HelperText>{props.helperText}</HelperText>
          ) : null}
        </Label>
      ) : null}
      <div className="d-flex align-items-stretch">
        <div className="d-block flex-grow-1">
          <Input
            id={props.name}
            {...field}
            type={props?.type || "text"}
            invalid={!!error}
            placeholder={props.placeholderText}
            className={props.customClass}
            disabled={props.disabled}
          />
          <FormFeedback>{error?.message}</FormFeedback>
        </div>
        <div className="d-block">{props.children}</div>
      </div>
    </FormGroup>
  );
}
