import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { GetProjectsDto } from "../../services/dtos/projects.dto";
import { MapProjectsItemContainer } from "./MapProjectsItemContainer";

interface MapProjectProps {
  onHandleItemClick: (item: GetProjectsDto) => void;
  status: string;
  filteredItems: GetProjectsDto[];
}

const MapProjectsList: React.FC<MapProjectProps> = ({
  onHandleItemClick,
  status,
  filteredItems,
}) => {
  const [itemInFocus, setItemInFocus] = useState<string>("");
  const [projects, setProjectList] = useState<GetProjectsDto[]>([]);

  useEffect(() => {
    setProjectList(filteredItems || []);
  }, [filteredItems]);

  if (status === "error") return <p>Error loading projects</p>;
  if (status === "loading") return <p>Loading...</p>;

  return projects.length === 0 ? (
    <div>No sites found</div>
  ) : (
    <>
      {projects.map((item, index) => (
        <MapProjectsItemContainer
          isInFocus={itemInFocus === item.id ? true : false}
          key={index}
          myObject={item}
          onHandleClick={() => {
            onHandleItemClick(item);
            setItemInFocus(item.id);
          }}
        />
      ))}
    </>
  );
};

export default MapProjectsList;
