import { useContext, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import supergraphic from "../../assets/img/bosch_supergraphic_strip.png";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCog,
  faHouse,
  faIndustry,
  faPersonDigging,
  faRightFromBracket,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { PageTitleContext } from "../../contexts/PageTitleProvider";
import UserService from "../../services/user.service";
import { useAuth } from "react-oidc-context";

export default function AppHeader() {
  const pageTitle = useContext(PageTitleContext);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const auth = useAuth();
  // console.log(location.pathname);
  const offCanvasStyle = {
    width: open ? "75%" : 0,
  };

  return (
    <>
      <Navbar
        light
        className="bg-primary navbar-dark"
        sticky={"top"}
        style={{
          borderTop: 0,
          borderImage: `url(${supergraphic}) 10 round`,
          borderBottom: "8px solid transparent",
          borderLeft: 0,
          borderRight: 0,
        }}
      >
        <NavbarBrand className="d-flex align-items-center">
          {location.pathname !== "/" ? (
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="p-2"
              onClick={() => navigate(-1)}
            />
          ) : null}

          <span className="fs-3 fw-bold">{pageTitle}</span>
        </NavbarBrand>
        <NavbarToggler
          className="border-0"
          onClick={() => setOpen(!open)}
          size={"sm"}
        />
      </Navbar>
      <Offcanvas isOpen={open} style={offCanvasStyle}>
        <OffcanvasHeader toggle={() => setOpen(!open)}>
          <Row className="align-items-center">
            <div className="col-3">
              <FontAwesomeIcon icon={faUserCircle} size={"2xl"} bounce />
            </div>
            <div className="col fs-5">
              <p className="my-0 fw-bolder my-0">{UserService.getUsername()}</p>
              <p className="my-0 fw-italic fs-6">
                {UserService.getUserCountry()}
              </p>
            </div>
          </Row>
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Row className="p-3">
            <ul className="list-group list-group-flush">
              <li className="list-group-item" onClick={() => setOpen(!open)}>
                <Link className="nav-link" to="/home">
                  <FontAwesomeIcon icon={faHouse} /> Home
                </Link>
              </li>
              <li className="list-group-item" onClick={() => setOpen(!open)}>
                <Link className="nav-link" to="/projects/create">
                  <FontAwesomeIcon icon={faPersonDigging} /> Create Project
                </Link>
              </li>
              <li className="list-group-item" onClick={() => setOpen(!open)}>
                <Link className="nav-link" to="/companies/create">
                  <FontAwesomeIcon icon={faIndustry} /> Create Company
                </Link>
              </li>
              <li className="list-group-item" onClick={() => setOpen(!open)}>
                <Link
                  className="nav-link"
                  to={
                    UserService.isBoschUser()
                      ? "/profile"
                      : process.env.REACT_APP_SKID_PROFILE_URL || ""
                  }
                >
                  <FontAwesomeIcon icon={faCog} />{" "}
                  {UserService.isBoschUser()
                    ? "My profile"
                    : "SingleKey ID My Profile"}
                </Link>
              </li>
              <li></li>
              <li
                className="list-group-item"
                onClick={() => {
                  auth.signoutRedirect();
                  navigate("/");
                  setOpen(!open);
                }}
              >
                <Link className="nav-link text-danger" to="/">
                  <FontAwesomeIcon icon={faRightFromBracket} /> Logout
                </Link>
              </li>
            </ul>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}
