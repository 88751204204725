import * as yup from "yup";

export const MapFilterSchema = yup.object({
  type: yup.string().notRequired(),
  estimatedValue: yup.string().notRequired(),
  active: yup.boolean().notRequired(),
});

export type MapFilterFormData = yup.InferType<typeof MapFilterSchema>;

export class MapFilterDto {
  type: string;
  estimatedValue: string;
  active: boolean;

  constructor() {
    this.type = "";
    this.estimatedValue = "";
    this.active = true;
  }
}
