import { useEffect, useState } from "react";

type Props = {
  filters: string[];
  onFilterChange: (x: string) => void;
};

type PillProps = {
  value: string;
  selectedValue: string;
  handleClick: (x: string) => void;
};

function Pill({ value, handleClick, selectedValue }: PillProps) {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    setActive(value === selectedValue);
  }, [selectedValue, value]);

  return (
    <li
      className="nav-item"
      onClick={() => {
        const currState = !isActive;
        setActive(currState);
        handleClick(currState ? value : "All");
      }}
    >
      <div className={`nav-link p-0 m-1 border ${isActive ? "active" : ""}`}>
        <span className="p-2">{value}</span>
      </div>
    </li>
  );
}

export function HorizontalScrollFilter({ filters, onFilterChange }: Props) {
  const [selectedValue, setSelectedValue] = useState("All");

  return (
    <ul
      className="nav nav-pills mb-3 w-100 hide-scrollbar"
      style={{
        overflowX: "auto",
        whiteSpace: "nowrap",
        flexWrap: "nowrap",
      }}
    >
      {filters.map((filter, index) => (
        <Pill
          key={index}
          value={filter}
          selectedValue={selectedValue}
          handleClick={(v) => {
            setSelectedValue(v);
            onFilterChange(v);
          }}
        />
      ))}
    </ul>
  );
}
