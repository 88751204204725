import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { Input, FormGroup, Label, FormFeedback } from "reactstrap";

class CheckboxParams {
  label?: string;
  currentValue?: string;
}

export function BooleanInput<T extends FieldValues>(
  props: UseControllerProps<T> & CheckboxParams
) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div>
            {props.label ? (
              <Label style={{ fontWeight: "500" }}>
                {props.label}{" "}
                <FormFeedback className="d-inline">
                  {error?.message}
                </FormFeedback>
              </Label>
            ) : null}
            <FormGroup check key={1}>
              <Input
                {...field}
                type="radio"
                onChange={() => field.onChange(true)}
                invalid={!!error}
                checked={field.value === true}
              />
              <Label for={"Yes"}>Yes</Label>
            </FormGroup>
            <FormGroup check key={2}>
              <Input
                {...field}
                type="radio"
                onChange={() => field.onChange(false)}
                invalid={!!error}
                checked={field.value === false}
              />
              <Label for={"No"}>No</Label>
            </FormGroup>
          </div>
        );
      }}
    />
  );
}
