import { useContext, useEffect, useReducer, useState } from "react";
import {
  ProjectStagesReducer,
  initializeProjectStages,
} from "../../reducers/project-stages.reducer";
import {
  CreateProjectDto,
  CreateProjectFormData,
} from "./create-project.schema";
import { useProjectAPI } from "../../services/projects.service";
import { useCompanyAPI } from "../../services/companies.service";
import { PageTitleDispatchContext } from "../../contexts/PageTitleProvider";
import { formatPhoneNumberIntl } from "../../constants/utils";
import { useGeolocation } from "../../hooks/useGeolocation";
import { ProjectForm } from "./ProjectForm";
import { Notify } from "../../components/layout/Notify";
import { validateProjectStages } from "./validateProjectStages";

export function CreateProject() {
  const pageTitleDispatch = useContext(PageTitleDispatchContext);

  const { useCreateCompanyByName } = useCompanyAPI();

  useEffect(() => {
    if (pageTitleDispatch) {
      pageTitleDispatch({
        type: "update",
        payload: "Record Project",
      });
    }
  }, [pageTitleDispatch]);

  const { useCreateProject } = useProjectAPI();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const {
    coords,
    geolocationError,
    promptUserLocation,
    geolocationStatusInfo,
  } = useGeolocation();

  const createProjectViaAPI = useCreateProject();

  const [responseError, setResponseError] = useState<unknown>();
  const [projectStages, dispatch] = useReducer(
    ProjectStagesReducer,
    initializeProjectStages()
  );

  useEffect(() => {
    setResponseError(geolocationError);
  }, [geolocationError]);

  const onSubmit = (data: CreateProjectFormData) => {
    data.siteManagerContact = formatPhoneNumberIntl(data.siteManagerContact);

    const invalidStages = validateProjectStages(projectStages);
    if (invalidStages.length > 0) {
      setResponseError(
        `The following on going stage(s) MUST contain AT LEAST one company: ${invalidStages.join(
          ", "
        )}`
      );
      return;
    }

    if (coords) {
      const projectDtoData: CreateProjectDto = {
        ...data,
        projectStages,
        latitude: coords.latitude,
        longitude: coords.longitude,
      };
      createProjectViaAPI.mutate(projectDtoData, {
        onSuccess: () => {
          setShowSuccessDialog(true);
        },
        onError: (error) => {
          console.error(error);
          setResponseError(error);
        },
      });
    } else {
      promptUserLocation(); // Put logic GPS fetch logic here.
    }
  };
  const createUpdateCompanyAPI = useCreateCompanyByName();

  return (
    <>
      <ProjectForm
        onSubmit={onSubmit}
        responseError={responseError}
        handleOptionCreation={handlePartialCompanyCreation}
        projectStages={projectStages}
        updateProjectStage={dispatch}
        geolocationStatusInfo={geolocationStatusInfo}
      />
      <Notify
        isVisible={showSuccessDialog}
        message="Project saved successfully."
        url="/projects"
      />
    </>
  );

  function handlePartialCompanyCreation(newOption: string) {
    return createUpdateCompanyAPI.mutateAsync(
      { name: newOption },
      {
        onError(error) {
          setResponseError(error);
        },
      }
    );
  }
}
