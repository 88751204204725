import * as yup from "yup";
import { ProjectStage } from "../../constants";
import { isValidPhoneNumber } from "libphonenumber-js";

export const CreateProjectSchema = yup.object({
  name: yup.string().required(),
  type: yup.string().required(),
  description: yup.string().required(),
  estimatedValue: yup.string().required(),
  address: yup.string().required(),
  isCompleted: yup.boolean().optional().default(false),
  leadContractor: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
    })
    .required(),
  siteManager: yup.string().required(),
  siteManagerContact: yup
    .string()
    .required()
    .test(
      "phoneNumber",
      "Invalid phone number: Start with country code: E.g: +254 followed by phone number",
      (value) => {
        return isValidPhoneNumber(value);
      }
    ),
  architect: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
    })
    .required(),
});

export type CreateProjectFormData = yup.InferType<typeof CreateProjectSchema>;

export type CreateProjectType = CreateProjectFormData & {
  projectStages: ProjectStage[];
  latitude?: number;
  longitude?: number;
  id?: string;
};

export class CreateProjectDto implements CreateProjectType {
  name: string;
  type: string;
  description: string;
  estimatedValue: string;
  address: string;
  leadContractor: { name: string; id: string };
  siteManager: string;
  siteManagerContact: string;
  architect: { name: string; id: string };
  projectStages: ProjectStage[];
  latitude?: number;
  longitude?: number;
  id?: string | undefined;
  isCompleted: boolean;

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.address = "";
    this.name = "";
    this.type = "";
    this.description = "";
    this.address = "";
    this.estimatedValue = "";
    this.leadContractor = { name: "", id: "" };
    this.projectStages = [];
    this.longitude = 0;
    this.latitude = 0;
    this.siteManager = "";
    this.siteManagerContact = "+"; // Add logic: To begin with country of logged in user
    this.architect = { name: "", id: "" };
    this.isCompleted = false;
  }
}
