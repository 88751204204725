/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { SearchInput } from "../../components/form/SearchInput";
import { HorizontalScrollFilter } from "../../components/form/HorizontalScrollFilter";
import { useCompanyAPI } from "../../services/companies.service";
import { GetCompaniesDto } from "../../services/dtos/companies.dto";
import { ListView } from "../../components/layout/ListView";
import { CONTRACTOR_SEGMENTS } from "../../constants";
import { PageTitleDispatchContext } from "../../contexts/PageTitleProvider";
import { useQuery } from "@tanstack/react-query";

export function CompanyProfiles() {
  const pageTitleDispatch = useContext(PageTitleDispatchContext);
  const { searchCompanies, useFetchCompanies } = useCompanyAPI();

  useEffect(() => {
    if (pageTitleDispatch) {
      pageTitleDispatch({
        type: "update",
        payload: "Company Profiles",
      });
    }
  }, [pageTitleDispatch]);

  const { status, data: companies } = useFetchCompanies();
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const getCompaniesViaSearch = useQuery({
    queryKey: ["companiesSearch", searchValue],
    queryFn: () => searchCompanies(searchValue),
    enabled: !!(searchValue !== undefined),
  });

  const [companyList, setCompanyList] = useState<GetCompaniesDto[]>([]);

  useEffect(() => {
    setCompanyList(companies || []);
  }, [companies]);

  useEffect(() => {
    if (getCompaniesViaSearch.isSuccess) {
      setCompanyList(getCompaniesViaSearch.data || []);
    }
  }, [getCompaniesViaSearch.data]);

  const handleFilterChange = (filter: string) => {
    if (filter === "All") {
      setCompanyList(companies || []);
    } else {
      const filteredCompanyList = companies?.filter((company) => {
        return company.businessSegments.includes(filter);
      });
      setCompanyList(filteredCompanyList || []);
    }
  };

  if (status === "error") return <p>Error loading projects</p>;
  if (status === "loading") return <p>Loading...</p>;

  return (
    <React.Fragment>
      <SearchInput
        onTextChange={setSearchValue}
        placeHolderText="Search by name, segment or address"
      />
      <HorizontalScrollFilter
        filters={["All", ...CONTRACTOR_SEGMENTS]}
        onFilterChange={handleFilterChange}
      />
      <ListView<GetCompaniesDto>
        linkTo="companies"
        items={companyList}
        renderItem={(company) => {
          return (
            <div className="row align-companys-center">
              <div className="col">
                <p className="fs-5 fw-bold my-0 text-wrap">{company.name}</p>

                <p className="my-0 fw-normal" style={{ fontSize: 14 }}>
                  <b>Segment: </b>

                  {company?.businessSegments?.length > 0 ? (
                    <span className="badge bg-secondary rounded-0 text-wrap">
                      {company.businessSegments.join(", ")}
                    </span>
                  ) : (
                    <i className="badge bg-warning rounded-0">Not available</i>
                  )}
                </p>
                <p className="my-0 fw-normal" style={{ fontSize: 14 }}>
                  <b>Contact: </b>
                  {company?.contacts?.length > 0 ? (
                    <span className="text-wrap">
                      {company.contacts[0]?.name}
                    </span>
                  ) : (
                    <i className="badge bg-warning rounded-0">Not available</i>
                  )}
                </p>
                <p className="my-0 fw-normal" style={{ fontSize: 14 }}>
                  <b>Location/Address: </b>
                  {company.street ? (
                    <span className="text-wrap">{company.street}</span>
                  ) : (
                    <i className="badge bg-warning rounded-0">Not available</i>
                  )}
                </p>
              </div>
            </div>
          );
        }}
      />
    </React.Fragment>
  );
}
