import * as yup from "yup";

export const COUNTRIES = [
  "Kenya",
  "Morocco",
  "Nigeria",
  "South Africa",
  "Ghana",
  "Tunisia",
  "Angola",
  "Egypt",
];

export const SignUpSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  country: yup.string().oneOf(COUNTRIES).required(),
});

export type SignUpFormData = yup.InferType<typeof SignUpSchema>;
