import { faAdd, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FieldErrors,
  FieldArrayWithId,
  Control,
  UseFieldArrayRemove,
  FieldArrayMethodProps,
} from "react-hook-form";
import { FormGroup, Label, Button } from "reactstrap";
import { FormInput } from "../../components/form/FormInput";
import { CompanyDataForm } from "./create-company.schema";

type Props = {
  errors: FieldErrors<CompanyDataForm>;
  fieldArray: FieldArrayWithId<CompanyDataForm>[];
  labelText: string;
  placeHolderText?: string;
  control: Control<CompanyDataForm>;
  removeName: UseFieldArrayRemove;
  addName: {
    (
      value: { name: string } | { name: string }[],
      options?: FieldArrayMethodProps | undefined
    ): void;
    (arg0: { name: string }): void;
  };
  fieldName: "dealerPurchaseFrom" | "servicePartners";
};

export function DynamicMultiInput({
  errors,
  fieldArray,
  control,
  removeName,
  addName,
  labelText,
  fieldName,
  placeHolderText,
}: Props) {
  return (
    <FormGroup>
      <hr />
      <Label className="mb-0" style={{ fontWeight: "500" }}>
        {labelText}
      </Label>
      <div
        className={`row ${
          errors[fieldName]?.message ? "border border-danger mx-1 pt-3" : ""
        } rounded m-1`}
      >
        {fieldArray.map((field, index) => {
          return (
            <div key={field.id} className="row g-1 align-items-stretch my-0">
              <div className="col-9 mt-0">
                <FormInput
                  name={`${fieldName}.${index}.name` as const}
                  control={control}
                  placeholderText={`${placeHolderText || "Entry"} ${index + 1}`}
                />
              </div>
              <div className="d-inline-flex col-3 mt-0 mb-3">
                <Button
                  onClick={() => {
                    removeName(index);
                  }}
                  color="danger"
                  className="me-1 align-items-center"
                  style={{ display: index === 0 ? "none" : "flex" }}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </Button>
                <Button
                  onClick={() => {
                    addName({ name: "" });
                  }}
                  className="me-1 align-items-center"
                  color="success"
                  style={{
                    display: index !== fieldArray.length - 1 ? "none" : "flex",
                  }}
                >
                  <FontAwesomeIcon icon={faAdd} />
                </Button>
              </div>
            </div>
          );
        })}
      </div>

      <p
        className="text-danger"
        style={{
          marginTop: "0.25rem",
          fontSize: "0.875rem",
        }}
      >
        {errors[fieldName]?.message}
      </p>
      <hr />
    </FormGroup>
  );
}
