import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

interface Props {
  url: string;
  icon: IconDefinition;
  title: string;
}

export function Tile({ url, icon, title }: Props) {
  return (
    <Link to={`/${url}`}>
      <Button
        className="rounded-1"
        style={{ width: "100%", height: "150px" }}
        color="primary"
      >
        <span className="d-block m-2">
          <FontAwesomeIcon icon={icon} size={"3x"} />
        </span>
        <span className="d-block fw-semibold">{title}</span>
      </Button>
    </Link>
  );
}
