import React from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { AppProps } from "../../common/app.props";

interface Props extends AppProps {
  cardHeader: string;
  cardTitle: string;
}
export function AppCard({ cardHeader, cardTitle, children }: Props) {
  return (
    <Card className="w-100 text-bg-light">
      <CardHeader className="h5 text-center font-weight-bold">
        {cardHeader}
      </CardHeader>
      {cardTitle ? (
        <CardTitle className="h5 text-center m-3">{cardTitle}</CardTitle>
      ) : null}
      <CardBody className="mt-0 d-flex justify-content-center align-items-center">
        {children}
      </CardBody>
    </Card>
  );
}
