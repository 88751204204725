import { User } from "oidc-client-ts";
import { GetAppUsersDto } from "./dtos/appUsers.dto";

import { jwtDecode } from "jwt-decode";
import { TokenPayload } from "./token.interface";

function getOidcUser() {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:ranger`
  );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
}

const getToken = () => getOidcUser()?.access_token;

const getTokenParsed = () =>
  jwtDecode(getOidcUser()?.access_token || "") as TokenPayload | undefined;

const getLocalStorageUsername = () => {
  const localData = localStorage.getItem("user");
  if (localData) {
    const user = JSON.parse(localData) as GetAppUsersDto;
    return `${user.firstName} ${user.lastName}`;
  } else {
    return "";
  }
};

const isBoschUser = () => getTokenParsed()?.["bosch-identity-type"];

const isLoggedIn = () => !!getToken();

const getUsername = () => {
  const boschUsername = `${getUserFirstName()} ${getUserLastName()}`;
  const singleKeyIDUsername = getLocalStorageUsername();
  return isBoschUser() ? boschUsername : singleKeyIDUsername;
};

const getUserFirstName = () => getTokenParsed()?.["name.firstName"];
const getUserLastName = () => getTokenParsed()?.["name.lastName"];

const getUserEmail = () => {
  return `${getTokenParsed()?.["email"]}`;
};

const getUserNT = () => {
  return `${getTokenParsed()?.["login"]}`;
};

const getUserID = () => getOidcUser()?.profile.sub;

const hasResourceRole = (role: string) => {
  const access = getTokenParsed()?.resource_access;
  return !!access && access["ranger"].roles.indexOf(role) >= 0;
};

const hasRole = (roles: any[]) => roles.some((role) => hasResourceRole(role));

// -- Extra user info from the local storage
const getUserCountry = () => {
  const userInfo = localStorage.getItem("user");
  const user: GetAppUsersDto = userInfo ? JSON.parse(userInfo) : null;
  return user?.country;
};

const UserService = {
  isBoschUser,
  getToken,
  getTokenParsed,
  hasRole,
  isLoggedIn,
  getUsername,
  getUserID,
  getUserEmail,
  getUserNT,
  getUserFirstName,
  getUserLastName,
  getUserCountry,
};

export default UserService;
