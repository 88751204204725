import { useEffect, useState } from "react";

export type GeolocationInfo = {
  status: "loading" | "unavailable" | "denied";
  message: string;
};

export function useGeolocation() {
  const [coords, setCoords] = useState<{
    latitude: number;
    longitude: number;
  }>();
  const [geolocationError, setGeolocationError] = useState<
    string | undefined
  >();
  const [geolocationStatusInfo, setGeolocationStatusInfo] = useState<
    GeolocationInfo | undefined
  >();

  const promptUserLocation = () => {
    setGeolocationStatusInfo({
      status: "loading",
      message: "Please wait while loading GPS coordinates.",
    });
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoords({ latitude, longitude });
        setGeolocationStatusInfo(undefined);
      },
      (error) => {
        switch (error.code) {
          case 1:
            setGeolocationError(
              `Location permission has been denied. Kindly enable location. If location is enabled, kindly close this tab and open it again.`
            );
            setGeolocationStatusInfo({
              status: "denied",
              message: `Location permission has been denied. Kindly enable location. If location is enabled, kindly close this tab and open it again.`,
            });
            console.error("Location permission has been denied.");
            break;
          case 2:
            setGeolocationError(`Position unavailable.`);
            setGeolocationStatusInfo({
              status: "unavailable",
              message: "Position is unavailable",
            });
            console.error("Position unavailable.");
            break;
          case 3:
            setGeolocationError("GPS timed out");
            console.log(`GPS timed out`);
            break;
          default:
            console.error("An unknown error occured");
            break;
        }
      },
      {
        timeout: 10 * 1000,
      }
    );
  };

  function handleGeolocationPermission() {
    if ("geolocation" in navigator) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          console.log("Location granted");
        } else if (result.state === "denied") {
          console.log("Denied location permission");
        }
        promptUserLocation();
      });
    } else {
      console.log("User device does not have geolocation");
    }
  }

  useEffect(() => {
    handleGeolocationPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    coords,
    geolocationError,
    promptUserLocation,
    geolocationStatusInfo,
  };
}
