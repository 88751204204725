import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { GetProjectsDto } from "../../services/dtos/projects.dto";
import { Link } from "react-router-dom";

interface MapItemProps {
  myObject: GetProjectsDto;
  isInFocus: Boolean;
  onHandleClick: (item: GetProjectsDto) => void;
}

export const MapProjectsItemContainer: React.FC<MapItemProps> = ({
  myObject,
  isInFocus,
  onHandleClick,
}) => {
  return (
    <div>
      {isInFocus ? (
        <div className="container">
          <div style={{ height: "12px" }}></div>
          <div
            className="row align-items-center"
            onClick={() => onHandleClick(myObject)}
            style={{
              border: "3px solid #005691",
              borderRadius: "10px",
              backgroundColor: "rgba(0, 86, 145, 0.2)",
              padding: "5px",
            }}
          >
            <div className="col-9">
              <b style={{ fontWeight: "700" }}>{myObject.name}</b>

              <p
                className="no-margin-padding"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  margin: 0,
                  padding: 0,
                }}
              >
                {myObject.description}
                <br />
                {myObject.type + " • " + myObject.address}
              </p>
            </div>
            <div className="col-2">
              <span className="m-4">
                <Link to={"/projects/" + myObject.id}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{
                      height: "24px",
                      color: "grey",
                    }}
                  />
                </Link>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div style={{ height: "12px" }}></div>
          <div
            className="row align-items-center"
            onClick={() => onHandleClick(myObject)}
          >
            <div className="col-9">
              <b style={{ fontWeight: "700" }}>{myObject.name}</b>

              <p
                className="no-margin-padding"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  margin: 0,
                  padding: 0,
                }}
              >
                {myObject.description}
                <br />
                {myObject.type + " • " + myObject.address}
              </p>
            </div>
            <div className="col-2">
              <span className="m-4">
                <Link to={"/projects/" + myObject.id}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{
                      height: "24px",
                      color: "grey",
                    }}
                  />
                </Link>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
