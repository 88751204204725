import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { Input, FormGroup, Label, FormFeedback, InputGroup } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";
import { HelperText } from "./HelperText";

class InputParams {
  label?: string;
  type?: InputType;
  children?: any;
  helperText?: string;
}
export function FormInputFloating<T extends FieldValues>(
  props: UseControllerProps<T> & InputParams
) {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return (
    <InputGroup>
      <FormGroup floating>
        <Input
          id={props.name}
          {...field}
          placeholder={props.label}
          type={props?.type || "text"}
          invalid={!!error}
        />
        {props.children}
        {props.label ? <Label for={props.name}>{props.label}</Label> : null}
        {props.helperText ? <HelperText>{props.helperText}</HelperText> : null}
        <FormFeedback>{error?.message}</FormFeedback>
      </FormGroup>
    </InputGroup>
  );
}
