import {
  faBuildingUser,
  faHelmetSafety,
  faIndustry,
  faMapLocationDot,
  faPersonDigging,
} from "@fortawesome/free-solid-svg-icons";
import { Tile } from "../../components/layout/Tile";
import { useContext, useEffect } from "react";
import { PageTitleDispatchContext } from "../../contexts/PageTitleProvider";

export default function Home() {
  const pageTitleDispatch = useContext(PageTitleDispatchContext);

  useEffect(() => {
    if (pageTitleDispatch) {
      pageTitleDispatch({
        type: "update",
        payload: "Home",
      });
    }
  }, [pageTitleDispatch]);

  return (
    <div className="row g-2 mt-1">
      <div className="col-6">
        <Tile
          url="projects/create"
          icon={faPersonDigging}
          title="Record Project"
        />
      </div>
      <div className="col-6">
        <Tile
          url="companies/create"
          icon={faIndustry}
          title="Register Company"
        />
      </div>
      <div className="col-6">
        <Tile url="projects" icon={faHelmetSafety} title="Projects" />
      </div>
      <div className="col-6">
        <Tile url="companies" icon={faBuildingUser} title="Company Profiles" />
      </div>
      <div className="col-12">
        <Tile url="map" icon={faMapLocationDot} title="Project Map" />
      </div>
    </div>
  );
}
