import React, { createContext, useReducer } from "react";

type Actions = {
  type: "update";
  payload: string;
};

function pageTitleReducer(state: string, action: Actions) {
  switch (action.type) {
    case "update":
      return action.payload;
    default:
      return state;
  }
}

export const PageTitleContext = createContext<string>("Home");

export const PageTitleDispatchContext =
  createContext<React.Dispatch<Actions> | null>(null);

interface Props {
  children?: React.ReactNode;
}

export const PageTitleProvider = ({ children }: Props) => {
  const [title, dispatch] = useReducer(pageTitleReducer, "Home");

  return (
    <PageTitleContext.Provider value={title}>
      <PageTitleDispatchContext.Provider value={dispatch}>
        {children}
      </PageTitleDispatchContext.Provider>
    </PageTitleContext.Provider>
  );
};
