import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { AppProps } from "../../common/app.props";

interface Props {
  showModal: boolean;
}

export function AlertDisplay({ showModal, children }: Props & AppProps) {
  const [modal, setModal] = useState(showModal);

  useEffect(() => {
    setModal(showModal);
  }, [showModal]);

  const toggle = () => setModal(!modal);

  return (
    <Modal isOpen={modal} centered size="sm">
      <ModalHeader toggle={toggle}>
        <span className="fw-bold text-center">Message</span>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
}
