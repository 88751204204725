import bosch_logo from "../../assets/img/brand/bosch_logo.svg";
import { SKIDOverlay } from "../../components/layout/SKIDOverlay";
import { useAuth } from "react-oidc-context";

export function Welcome() {
  const { signinRedirect } = useAuth();
  return (
    <div>
      <div
        className="row"
        style={{
          overflow: "hidden",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${bosch_logo})`,
          height: 120,
        }}
      ></div>
      <div className="d-flex align-items-center mx-2">
        <div className="container text-center">
          <h1 className="text-body-emphasis">Hello!</h1>
          <p className="lead">Please authenticate yourself</p>
          <div className="row">
            <div className="col d-grid">
              <button
                className="btn btn-success my-2 rounded-1"
                onClick={(e) => {
                  e.preventDefault();
                  signinRedirect({
                    extraQueryParams: { style_id: "pt_bsch_mgmt" },
                  });
                }}
              >
                SingleKey ID login
              </button>
              <button
                className="btn btn-primary my-2 rounded-1"
                onClick={(e) => {
                  e.preventDefault();
                  signinRedirect({
                    extraQueryParams: {
                      kc_idp_hint: "bosch-azure-oidc",
                    },
                  });
                }}
              >
                Bosch Login
              </button>
            </div>
            <div>
              <SKIDOverlay />
            </div>
          </div>
          <div className="position-absolute bottom-0 start-50 translate-middle-x">
            <p>
              <a href="/THIRD-PARTY-LICENSES.txt">OSS Licenses</a>
            </p>
            <p>
              <a href="https://main.dta8azcf880n8.amplifyapp.com/">
                Legal Imprint
              </a>
            </p>
            <p>©️ 2023</p>
          </div>
        </div>
      </div>
    </div>
  );
}
