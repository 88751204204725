import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { PageTitleDispatchContext } from "../../contexts/PageTitleProvider";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/user.service";
import { useAuth } from "react-oidc-context";

export function Profile() {
  const navigate = useNavigate();
  const pageTitleDispatch = useContext(PageTitleDispatchContext);
  // const [myDetails, setMyDetails] = useState<any>();
  const auth = useAuth();

  useEffect(() => {
    // const details = UserService.getTokenParsed();
    // setMyDetails(details);

    if (pageTitleDispatch) {
      pageTitleDispatch({
        type: "update",
        payload: "Settings",
      });
    }
  }, [pageTitleDispatch]);
  const rowStyle = {
    paddingTop: "20px",
  };
  const divPadding = {
    paddingTop: "10px",
  };

  const handleRedirect = () => {
    window.location.href = "https://main.dta8azcf880n8.amplifyapp.com/";
  };

  return (
    <>
      <div className="container">
        <div className="row" style={rowStyle}>
          <div className=" col-3">
            <span className="d-block m-2">
              <FontAwesomeIcon icon={faUser} size={"3x"} />
            </span>
          </div>
          <div className=" col-9">
            <span className="h5">{UserService.getUsername()}</span>
            <br />
            <span className="text-secondary">
              {"@ " + UserService.getUserNT()}
            </span>
            <br />
            <span className="text-secondary">{UserService.getUserEmail()}</span>
          </div>
        </div>
      </div>
      <div>
        <div className="p-3" style={divPadding}>
          <span className="h6">Settings</span>
          <br />
        </div>
        <div>
          <ul className="list-group list-unstyled">
            {/* <li className="list-group-item" style={divPadding}>
              Change language
            </li>
            <li className="list-group-item" style={divPadding}>
              Change password
            </li> */}
            <li
              className="list-group-item text-danger"
              style={divPadding}
              onClick={() => {
                auth.signoutSilent();
                navigate("/");
              }}
            >
              Logout
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div className="p-3" style={divPadding}>
          <span className="h6">Legal Notices</span>
          <br />
        </div>
        <div>
          <ul className="list-group list-unstyled">
            <li
              className="list-group-item"
              style={divPadding}
              onClick={handleRedirect}
            >
              Privacy policy
            </li>
            <li
              className="list-group-item"
              style={divPadding}
              onClick={handleRedirect}
            >
              Terms and conditions
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div className="p-3"></div>
        <span className="small">Powered by Appcrunch</span>
        <br />
        <span className="small">©2024 Robert Bosch, all rights reserved.</span>
      </div>
    </>
  );
}
