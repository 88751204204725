import { ProjectStage, ProjectStatus } from "../../constants";

export function validateProjectStages(stages: ProjectStage[]) {
  const invalidStages = stages.reduce((acc, curr) => {
    if (curr.status === ProjectStatus.ONGOING && curr.companies.length < 1) {
      acc.push(curr.name);
    }
    return acc;
  }, [] as string[]);

  return invalidStages;
}
