import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import Select from "react-select/creatable";
import { useCompanyAPI } from "../../services/companies.service";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { FormGroup, Label } from "reactstrap";
import { SingleValue } from "react-select";

export interface Option {
  readonly label: string;
  readonly value: string;
}

type Props = {
  handleCreation: (x: string) => Promise<AxiosResponse<any, any>>;
  placeholder: string;
  isDisabled: boolean;
  label: string;
};

export function ReactFormCreatableSelect<T extends FieldValues>(
  props: Props & UseControllerProps<T>
) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [value, setValue] = useState<Option | null>();
  const { useFetchCompanies } = useCompanyAPI();

  const companyQuery = useFetchCompanies();

  useEffect(() => {
    // console.log("useEffect, data changed....");
    if (companyQuery.status === "success") {
      if (companyQuery.data) {
        const options = companyQuery.data.map((company) => ({
          label: company.name,
          value: company.id,
        }));
        setOptions(options);
      }
    }
  }, [companyQuery.data, companyQuery.status]);

  if (companyQuery.status === "loading") return <p>Loading...</p>;

  if (companyQuery.status === "error") return <p>Error loading companies</p>;

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState }) => {
        return (
          <FormGroup>
            <Label for={props.name} style={{ fontWeight: "500" }}>
              {props.label}
            </Label>
            <Select
              inputId={props.name}
              isClearable
              options={options}
              isDisabled={props.isDisabled || isLoading}
              isLoading={isLoading}
              value={
                value || { label: field.value?.name, value: field.value?.id }
              }
              onChange={(newValue: SingleValue<Option>) => {
                if (newValue) {
                  const selectedValue = {
                    id: newValue.value,
                    name: newValue.label,
                  };
                  field.onChange(selectedValue);
                } else {
                  setValue(newValue);
                  field.onChange(newValue);
                }
              }}
              onCreateOption={async (inputValue: string) => {
                setIsLoading(true);
                try {
                  const { data } = await props.handleCreation(inputValue);
                  const createdOption = {
                    label: data.name,
                    value: data.id,
                  };
                  setIsLoading(false);
                  setOptions((prev) => [...prev, createdOption]);
                  // Update the data
                  companyQuery.refetch();
                  setValue(createdOption);
                  field.onChange(data);
                } catch (error) {
                  // TODO: Proper logger library to be implemented.
                  alert("Company could not be created. Try again.");
                  console.error(error);
                  setIsLoading(false);
                }
              }}
              placeholder={props.placeholder}
              styles={{
                control(base) {
                  return {
                    ...base,
                    borderColor: fieldState.error ? "#ed0007" : "lightgrey",
                  };
                },
              }}
            />

            {fieldState.error ? (
              <small style={{ color: "#ed0007" }}>
                {props.name} is a required field
              </small>
            ) : null}
          </FormGroup>
        );
      }}
    />
  );
}
