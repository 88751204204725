import { Link } from "react-router-dom";

interface ListViewProps<T> {
  items: T[] | undefined;
  linkTo: string;
  renderItem: (item: T) => React.ReactNode;
}
export function ListView<K extends { name: string; id: string }>({
  items,
  linkTo,
  renderItem,
}: ListViewProps<K>) {
  return (
    <div className="list-group rounded-0 mb-6">
      {items?.map((item, index) => {
        return (
          <Link
            to={`/${linkTo}/${item.id}`}
            className="list-group-item"
            key={index}
          >
            {renderItem(item)}
          </Link>
        );
      })}
    </div>
  );
}
