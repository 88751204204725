/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { SearchInput } from "../../components/form/SearchInput";
import { ListView } from "../../components/layout/ListView";
import { HorizontalScrollFilter } from "../../components/form/HorizontalScrollFilter";
import { PROJECT_TYPES } from "../../constants";
import { useProjectAPI } from "../../services/projects.service";
import { GetProjectsDto } from "../../services/dtos/projects.dto";
import { multiFilterListOfObjectsByKey } from "../../constants/utils";
import { PageTitleDispatchContext } from "../../contexts/PageTitleProvider";
import { useQuery } from "@tanstack/react-query";

export function ProjectSearchList() {
  const pageTitleDispatch = useContext(PageTitleDispatchContext);

  useEffect(() => {
    if (pageTitleDispatch) {
      pageTitleDispatch({
        type: "update",
        payload: "Projects",
      });
    }
  }, [pageTitleDispatch]);

  const { useFetchProjects, searchProjects } = useProjectAPI();

  const { status, data } = useFetchProjects();
  const [projectList, setProjectList] = useState<GetProjectsDto[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    setProjectList(data || []);
  }, [data]);

  const handleFilterChange = (filter: string) => {
    if (filter === "All") {
      setProjectList(data || []);
    } else {
      const filteredProjectList = multiFilterListOfObjectsByKey<GetProjectsDto>(
        data || [],
        [filter],
        "type"
      );
      setProjectList(filteredProjectList);
    }
  };

  const getProjectsViaSearch = useQuery({
    queryKey: ["projectsSearch", searchValue],
    queryFn: () => searchProjects(searchValue),
    enabled: !!(searchValue !== undefined),
  });

  useEffect(() => {
    if (getProjectsViaSearch.isSuccess) {
      setProjectList(getProjectsViaSearch.data || []);
    }
  }, [getProjectsViaSearch.data]);

  if (status === "error") return <p>Error loading projects</p>;
  if (status === "loading") return <p>Loading...</p>;

  return (
    <React.Fragment>
      <SearchInput
        onTextChange={setSearchValue}
        placeHolderText="Search by name or type or address"
      />
      <HorizontalScrollFilter
        filters={["All", ...PROJECT_TYPES.sort()]}
        onFilterChange={handleFilterChange}
      />
      <ListView<GetProjectsDto>
        linkTo="projects"
        items={projectList}
        renderItem={(item) => {
          return (
            <div className="row align-items-center">
              <div className="col-8">
                <p
                  className="fw-bold my-0 text-wrap"
                  style={{ fontWeight: "500" }}
                >
                  {item.name}
                </p>
              </div>
              <div className="col-4">
                <p className="badge my-0 bg-primary float-end rounded-0">
                  {item.type}
                </p>
              </div>
            </div>
          );
        }}
      />
    </React.Fragment>
  );
}
