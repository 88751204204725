import {
  Control,
  FieldArrayMethodProps,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayRemove,
} from "react-hook-form";
import { CompanyDataForm } from "./create-company.schema";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { FormInputFloating } from "../../components/form/FormInputFloating";
import { faTrashCan, faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HelperText } from "../../components/form/HelperText";

type Props = {
  errors: FieldErrors<CompanyDataForm>;
  fieldArray: FieldArrayWithId<CompanyDataForm>[];
  control: Control<CompanyDataForm>;
  addContact: {
    (
      value:
        | { name: string; phoneNumber: string; position: string; email: string }
        | {
            name: string;
            phoneNumber: string;
            position: string;
            email: string;
          }[],
      options?: FieldArrayMethodProps | undefined
    ): void;
    (arg0: {
      name: string;
      phoneNumber: string;
      email: string;
      position: string;
    }): void;
  };
  removeContact: UseFieldArrayRemove;
};

export function ContactPerson({
  fieldArray,
  control,
  addContact,
  removeContact,
}: Props) {
  return (
    <FormGroup>
      {fieldArray.map((field, index) => (
        <div key={field.id}>
          <Row className="align-items-center mb-1">
            <Col>
              <Label style={{ fontWeight: "500", marginBottom: 0 }}>
                <span>
                  Contact Person {index + 1}{" "}
                  {index + 1 === 1 ? (
                    <HelperText>Decision Maker</HelperText>
                  ) : null}
                </span>
              </Label>
            </Col>

            <Col>
              <div className="float-end">
                {index !== fieldArray.length - 1 ? null : (
                  <Button
                    onClick={() => {
                      addContact({
                        email: "",
                        name: "",
                        phoneNumber: "",
                        position: "",
                      });
                    }}
                    className="me-1"
                    color="success"
                  >
                    <FontAwesomeIcon icon={faAdd} />
                  </Button>
                )}
                {index === 0 ? null : (
                  <Button
                    onClick={() => {
                      removeContact(index);
                    }}
                    color="danger"
                    className="me-1 align-items-center"
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </Button>
                )}
              </div>
            </Col>
          </Row>

          <FormInputFloating
            name={`contacts.${index}.name`}
            label="Name"
            control={control}
          />
          <FormInputFloating
            name={`contacts.${index}.position`}
            label="Position"
            control={control}
          />
          <FormInputFloating
            name={`contacts.${index}.phoneNumber`}
            label="Phone Number"
            control={control}
            type="tel"
            helperText="Start with country code: E.g: +254 followed by phone number"
          />
          <FormInputFloating
            name={`contacts.${index}.email`}
            label="Email"
            control={control}
          />
        </div>
      ))}
    </FormGroup>
  );
}
