import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from "recharts";
import { BOSCH_COLORS, CHART_CONFIG } from "../../constants";
import { AppCard } from "./AppCard";

interface BarChartProps<T> {
  data: T[];
  xKey: keyof T;
  yKey: keyof T;
}

export function AppVerticalBarChart<TData>({
  data,
  xKey,
  yKey,
}: BarChartProps<TData>) {
  return (
    <AppCard cardHeader="Qsn goes here" cardTitle="Test">
      <ResponsiveContainer width="100%" height={CHART_CONFIG.height}>
        <BarChart data={data} layout="vertical">
          <CartesianGrid strokeDasharray="4 4" />
          <XAxis type="number" />
          <YAxis type="category" dataKey={xKey as string} />
          <Tooltip />
          <Legend />
          <Bar dataKey={yKey as string} fill={BOSCH_COLORS.blue} />
        </BarChart>
      </ResponsiveContainer>
    </AppCard>
  );
}
