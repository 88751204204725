import { AppProps } from "../../common/app.props";

export const CenterBox = ({
  children,
  style,
}: AppProps & { style?: Object }) => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 text-center h3">
      <div className="d-block" style={style}>
        {children}
      </div>
    </div>
  );
};
