import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { FormGroup, FormFeedback, Label, Input } from "reactstrap";
import { HelperText } from "./HelperText";

interface SelectParams {
  label?: string;
  children?: any;
  disabled?: boolean | undefined;
  disableFloating?: boolean;
  options: string[];
  helperText?: string;
}
export function FormSelect<T extends FieldValues>(
  props: UseControllerProps<T> & SelectParams
) {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return (
    <FormGroup>
      {props.label ? (
        <Label for={props.name} style={{ fontWeight: "500" }}>
          {props.label}
          <br />
          {props.helperText ? (
            <HelperText>{props.helperText}</HelperText>
          ) : null}
        </Label>
      ) : null}

      <Input
        id={props.name}
        {...field}
        type="select"
        invalid={!!error}
        disabled={props.disabled}
      >
        <option key={"0.999"} value={""}>
          Select option
        </option>
        {props.options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Input>
      <FormFeedback>{error?.message}</FormFeedback>
    </FormGroup>
  );
}
