import UserService from "../../services/user.service";
import { useAppUserAPI } from "../../services/app-user.service";
import { useQuery } from "@tanstack/react-query";
import { Outlet, useLocation } from "react-router-dom";
import Home from "../../routes/home/Home";
import { SignUp } from "../../routes/auth/signup";
import { handleAPIError } from "../../services/utils";
import { ErrorHandler } from "./ErrorDisplay";
import { APP_VIEW, APP_WRITE } from "../../common/auth-roles";
import { GetAppUsersDto } from "../../services/dtos/appUsers.dto";
import { CenterBox } from "./CenterBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { BOSCH_COLORS } from "../../constants";
import { useAuth } from "react-oidc-context";
import { Button } from "reactstrap";

export const RenderOnAuthenticated = () => {
  const auth = useAuth();
  const userID = UserService.getUserID();
  const { fetchAppUserInfo } = useAppUserAPI();
  const location = useLocation();

  const { data, isLoading, isSuccess, error } = useQuery(
    ["user", userID],
    () => fetchAppUserInfo(userID || ""),
    {
      retry: 0,
      staleTime: Infinity,
    }
  );

  const updateLoggedInUserInfo = (userInfo: GetAppUsersDto) => {
    localStorage.setItem("user", JSON.stringify(userInfo));
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    const apiError = handleAPIError(error);
    if (apiError.code === 404) {
      return <SignUp />;
    } else {
      return <ErrorHandler error={apiError} />;
    }
  }

  if (isSuccess) {
    if (data) updateLoggedInUserInfo(data);
    return auth.isAuthenticated ? (
      UserService.hasRole([APP_VIEW, APP_WRITE]) ? (
        <div>{location.pathname === "/" ? <Home /> : <Outlet />}</div>
      ) : (
        <CenterBox style={{ color: BOSCH_COLORS.blue }}>
          <p>
            <FontAwesomeIcon icon={faThumbsUp} size="2x" bounce />
          </p>
          <p className="fw-bold">Your sign up is confirmed</p>
          <p className="fw-bold fs-5">
            You will receive an email from the Appcrunch Team shortly with the
            access details.
          </p>
          <Button
            onClick={() => {
              // Edge case, Bosch Employees.
              // Use the auth settings to capture this edge case.
              auth.signinRedirect(auth.settings);
            }}
            color="primary"
          >
            <FontAwesomeIcon icon={faRefresh} spin /> Refresh
          </Button>
        </CenterBox>
      )
    ) : null;
  }

  return null;
};
