import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  ListGroup,
  ListGroupItem,
  Accordion,
} from "reactstrap";
import { Company, ProjectStage, ProjectStatus } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { CreateSelect } from "../components/form/CreateableSelect";
import { useCompanyAPI } from "../services/companies.service";
import { useState } from "react";
interface Props {
  projectStages: ProjectStage[];
  onStageStatusChange: (name: string, status: ProjectStatus) => void;
  onStageCompanyDeletion: (stageName: string, companyID: string) => void;
  onStageCompanyAddition: (stageName: string, company: Company) => void;
}

export function ProjectStages({
  projectStages,
  onStageCompanyDeletion,
  onStageStatusChange,
  onStageCompanyAddition,
}: Props) {
  const { useCreateCompanyByName } = useCompanyAPI();
  const mutation = useCreateCompanyByName();

  const [open, setOpen] = useState("");
  const toggle = (id: string) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  return (
    <>
      <Accordion open={open} toggle={toggle}>
        {projectStages.map((stage, index) => {
          return (
            <AccordionItem key={index}>
              <AccordionHeader targetId={`${index}`}>
                <div className="w-100 d-flex flex-row justify-content-between">
                  {stage.name}
                  <Badge
                    color={
                      stage.status === "PENDING"
                        ? "secondary"
                        : stage.status === "COMPLETED"
                        ? "info"
                        : stage.status === "ONGOING"
                        ? "success"
                        : "danger"
                    }
                    className="mx-2"
                  >
                    {stage.status}
                  </Badge>
                </div>
              </AccordionHeader>
              <AccordionBody accordionId={`${index}`}>
                <p className="fw-bold text-decoration-underline mb-1">
                  Project Status
                </p>
                <select
                  className="form-select mb-3"
                  onChange={({ target: { value } }) => {
                    onStageStatusChange(stage.name, value as ProjectStatus);
                  }}
                  value={stage.status || ProjectStatus.NO_INFO}
                >
                  {Object.values(ProjectStatus).map((value, index) => {
                    return (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
                <p className="fw-bold text-decoration-underline mb-1">
                  Companies
                </p>
                <div>
                  <ListGroup flush>
                    {stage.companies.map((company, index) => {
                      return (
                        <ListGroupItem key={index} className="px-0 py-2">
                          <div className="row w-100 align-items-center mx-0">
                            <div className="col-2 pr-0">{`${index + 1}. `}</div>
                            <div className="col-8 px-0">
                              <span>{company.name}</span>
                            </div>
                            <div className="col-2 d-flex justify-content-center">
                              <FontAwesomeIcon
                                icon={faCircleXmark}
                                style={{
                                  width: "1.3rem",
                                  height: "1.3rem",
                                }}
                                className={"text-danger mx-2"}
                                onClick={() => {
                                  onStageCompanyDeletion(
                                    stage.name,
                                    company.id
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                  <CreateSelect
                    isDisabled={stage.status === ProjectStatus.NO_INFO}
                    handleCreation={(newOption) => {
                      return mutation.mutateAsync({ name: newOption });
                    }}
                    handleChange={(value) => {
                      if (value) {
                        onStageCompanyAddition(stage.name, {
                          id: value.value,
                          name: value.label,
                        });
                      }
                    }}
                    placeholder="Select Company"
                  />
                </div>
              </AccordionBody>
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
}
