import { useMutation, useQuery } from "@tanstack/react-query";
import { BASE_API_URL } from "../constants";
import { CreateProjectDto } from "../routes/project/create-project.schema";
import { GetProjectsDto } from "./dtos/projects.dto";
import { useAxiosInstance } from "../hooks/useAxiosInstance";

export const useProjectAPI = () => {
  const axiosInstance = useAxiosInstance();

  const getProjectByID = async (projectID?: string) => {
    try {
      if (projectID) {
        const { data } = await axiosInstance.get(`projects/${projectID}`);
        return data as CreateProjectDto;
      }
      const response = new CreateProjectDto();
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const useFetchProjects = () => {
    return useQuery({
      queryKey: ["projects"],
      queryFn: async (): Promise<Array<GetProjectsDto>> => {
        const { data } = await axiosInstance.get("projects");
        return data;
      },
    });
  };

  const useFilterProjects = (
    queryText?: string,
    type?: string,
    estimatedValue?: string
  ) => {
    return useQuery({
      queryKey: ["projects", queryText, type, estimatedValue],
      queryFn: async (): Promise<Array<GetProjectsDto>> => {
        const queryParams = new URLSearchParams();
        if (type) {
          queryParams.append("type", type);
        }
        if (queryText) {
          queryParams.append("search", queryText);
        }
        if (estimatedValue) {
          queryParams.append("estimatedValue", estimatedValue);
        }
        const { data } = await axiosInstance.get(
          "projects?" + queryParams.toString()
        );
        console.log("url is = projects?" + queryParams.toString());
        console.log("data collected is = " + data[0].name);
        return data;
      },
    });
  };

  const useCreateProject = () => {
    return useMutation({
      mutationFn: (createProjectDto: CreateProjectDto) => {
        return axiosInstance.post(
          BASE_API_URL.concat("projects"),
          createProjectDto
        );
      },
    });
  };

  const searchProjects = async (search?: string) => {
    try {
      const { data } = await axiosInstance.get<Array<GetProjectsDto>>(
        BASE_API_URL.concat(`projects?search=${search}`)
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const useUpdateProject = () => {
    return useMutation({
      mutationFn: ({
        createProjectDto,
        projectID,
      }: {
        createProjectDto: CreateProjectDto;
        projectID: string;
      }) => {
        return axiosInstance.patch(
          BASE_API_URL.concat(`projects/${projectID}`),
          createProjectDto
        );
      },
    });
  };

  return {
    useCreateProject,
    useUpdateProject,
    getProjectByID,
    useFetchProjects,
    useFilterProjects,
    searchProjects,
  };
};
