/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./scss/custom.scss";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

import Home from "./routes/home/Home";
import ErrorPage from "./routes/ErrorPage";
import { CreateCompany } from "./routes/company/CreateCompany";
import { CreateProject } from "./routes/project/CreateProject";
import { CompanyProfiles } from "./routes/company/CompanyProfiles";
import { ProjectSearchList } from "./routes/project/ListProjects";
import { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MapScreen } from "./routes/map/MapScreen";
import { Profile } from "./routes/profile/Profile";
import UserService from "./services/user.service";
import { UpdateProject } from "./routes/project/UpdateProject";
import { UpdateCompany } from "./routes/company/UpdateCompany";
import { SignUp } from "./routes/auth/signup";
import { Dashboard } from "./routes/dashboard/Dashboard";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "projects",
        element: <ProjectSearchList />,
      },
      {
        path: "projects/create",
        element: <CreateProject />,
      },
      {
        path: "projects/:projectID",
        element: <UpdateProject />,
      },
      {
        path: "companies",
        element: <CompanyProfiles />,
      },
      {
        path: "companies/create",
        element: <CreateCompany />,
      },
      {
        path: "companies/:companyID",
        element: <UpdateCompany />,
      },
      {
        path: "map",
        element: <MapScreen />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
    ],
  },
]);

const queryClient = new QueryClient();

const renderApp = () =>
  root.render(
    <React.StrictMode>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{
          persister: createSyncStoragePersister({
            storage: window.localStorage,
          }),
        }}
      >
        <AuthProvider
          authority={process.env.REACT_APP_AUTH_URL}
          client_id="ranger"
          redirect_uri={process.env.REACT_APP_REDIRECT_URI}
          post_logout_redirect_uri={process.env.REACT_APP_REDIRECT_URI}
          onSigninCallback={(user) => {
            window.history.replaceState(
              {},
              document.title,
              window.location.pathname
            );
          }}
          onSignoutCallback={(): void => {
            // go to home after logout
            window.location.pathname = "";
          }}
          userStore={new WebStorageStateStore({ store: window.localStorage })}
        >
          <RouterProvider router={router} />
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </PersistQueryClientProvider>
    </React.StrictMode>
  );
renderApp();
// UserService.initKeycloak(renderApp);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
