import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

interface Props {
  message: string;
  isVisible: boolean;
  url: string;
}
export function Notify({ message, isVisible, url }: Props) {
  const navigate = useNavigate();
  return (
    <Modal isOpen={isVisible} className="position-sticky top-50">
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            navigate(url, { replace: true });
          }}
        >
          Proceed
        </Button>
      </ModalFooter>
    </Modal>
  );
}
