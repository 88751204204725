import { CreateCompanyFormData } from "./create-company.schema";
import { useCompanyAPI } from "../../services/companies.service";
import { useContext, useEffect, useState } from "react";
import { PageTitleDispatchContext } from "../../contexts/PageTitleProvider";
import { formatPhoneNumberIntl } from "../../constants/utils";
import { Notify } from "../../components/layout/Notify";
import { CompanyForm } from "./CompanyForm";

export function CreateCompany() {
  const pageTitleDispatch = useContext(PageTitleDispatchContext);
  const { useRegisterCompany } = useCompanyAPI();

  useEffect(() => {
    if (pageTitleDispatch) {
      pageTitleDispatch({
        type: "update",
        payload: "Create Company",
      });
    }
  }, [pageTitleDispatch]);

  const registerCompanyAPI = useRegisterCompany();
  const [responseError, setResponseError] = useState<unknown>();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const onSubmit = (companyDto: CreateCompanyFormData) => {
    // Handle case to invalidate service partners when value is updated.
    if (!companyDto.serviceProviderType.includes("Service Partner")) {
      companyDto.servicePartners = [];
    }
    companyDto.contacts = companyDto?.contacts?.map((contact) => {
      return Object.assign({}, contact, {
        phoneNumber: formatPhoneNumberIntl(contact.phoneNumber),
      });
    });

    registerCompanyAPI.mutate(companyDto, {
      onSuccess: (response) => {
        setShowSuccessDialog(true);
      },
      onError: (error) => {
        console.error(error);
        setResponseError(error);
      },
    });
  };

  return (
    <>
      <CompanyForm onSubmit={onSubmit} responseError={responseError} />
      <Notify
        isVisible={showSuccessDialog}
        message="Company saved successfully."
        url="/companies"
      />
    </>
  );
}
